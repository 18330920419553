import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Layout from "../components/layout";
import bluebg from "../images/blue-horse.jpeg";

const StyledH1 = styled.h1`
  color: var(--primary-color);
`;

const LinkContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLink = styled(Link)`
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  height: 20px;
  width: 150px;
  border-radius: 5px;
  background: var(--primary-color);
  color: #ffffff;
  padding: 10px 20px;
  margin: 20px;
  text-decoration: none;
  transition: all 0.3s;
  &:hover {
    background: var(--primary-hover-color);
  }
`;

const IndexPage = () => {
  return (
    <Layout background={bluebg}>
      <div>
        <StyledH1>Hi! I'm Blue!</StyledH1>
        <StaticImage
          src="../images/blue-thomas.jpeg"
          alt="Blue and Thomas"
          placeholder="blurred"
          layout="constrained"
          style={{ maxWidth: "500px" }}
          imgStyle={{ borderRadius: "5px" }}
        />
      </div>
    </Layout>
  );
};

export default IndexPage;
